<template>
  <div class="tw-container">
    <ul>
      <!-- 付款方式 -->
      <li>
        <h4 class="tw-border-start fw-bolder mb-3">付款方式</h4>
        <div class="form-floating mb-3">
          <input
            type="text"
            class="form-control"
            id="ReceiptTitle"
            placeholder="付款方式"
            value="匯款"
            disabled
          />
          <label for="ReceiptTitle">付款方式</label>
        </div>
        <ul class="text-start fw-bolder alert alert-warning">
          <li class="mb-2">[ 匯款帳號 ]</li>
          <li class="mb-2">中國信託銀行 豐原分行</li>
          <li class="mb-2">銀行代號: (822)</li>
          <li class="mb-2">帳號: 392540825738</li>
          <li class="mb-2">戶名: 優碼興科技股份有限公司</li>
        </ul>
      </li>
      <hr />
      <!-- 付款通知 -->
      <li>
        <h4 class="tw-border-start fw-bolder mb-3">付款通知</h4>
        <div>
          <!-- 銀行代碼 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="BankCode"
              placeholder="搜尋銀行"
              v-model="searchBankCode"
            />
            <label for="BankCode">搜尋銀行</label>
          </div>
          <div
            class="border p-3 mb-5"
            style="height: 200px; overflow-y: scroll"
          >
            <ul class="list" v-if="!banksCode.loading">
              <li
                class="text-start"
                v-for="data in banksCode.data"
                :key="data.bankCode"
              >
                <input
                  :id="`bank${data.bankCode}`"
                  type="radio"
                  name="bank"
                  :value="data.bankCode"
                  v-model="paymentNotification.bankCode"
                />
                <label :for="`bank${data.bankCode}`"
                  >{{ data.bankCode }} {{ data.name }}</label
                >
              </li>
            </ul>
            <AreaLoading v-else></AreaLoading>
          </div>
          <!-- 末五碼 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="LastFiveDigit"
              placeholder="帳號末五碼"
              maxlength="5"
              v-model="paymentNotification.accountNumber"
            />
            <label for="LastFiveDigit">帳號末五碼</label>
          </div>
          <!-- 匯款金額 -->
          <div class="form-floating mb-3">
            <input
              onwheel="this.blur()"
              type="number"
              class="form-control"
              id="RemittanceAmount"
              placeholder="匯款金額"
              :value="paymentNotification.money"
              disabled
            />
            <label for="RemittanceAmount">匯款金額</label>
          </div>
          <!-- 備註 -->
          <div class="form-floating mb-3">
            <textarea
              class="form-control"
              placeholder="備註"
              id="Note"
              style="height: 100px"
              maxlength="300"
              v-model="paymentNotification.note"
            ></textarea>
            <label for="Note">備註(300字)</label>
          </div>
          <!-- 匯款時間 -->
          <div class="form-floating mb-3">
            <input
              class="form-control"
              type="datetime-local"
              id="paymentTime"
              name="meeting-time"
              v-model="paymentNotification.paymentTime"
              placeholder="付款時間"
            />
            <label for="paymentTime" class="mb-2">付款時間</label>
          </div>
        </div>
      </li>
      <hr />
      <!-- 前往 -->
      <li>
        <div class="mt-5 d-flex justify-content-between">
          <router-link
            to="/user/PaymentRecord"
            class="tw-btn tw-btn-secondary fw-bold"
            >略過</router-link
          >
          <button
            class="tw-btn tw-btn-success fw-bold"
            @click="updatePaymentNotification"
          >
            送出
          </button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      serverToken: '',
      searchBankCode: '',
      banksCode: {
        loading: true,
        data: []
      },
      recordBanksCode: [],
      paymentNotification: {
        id: 0,
        money: 0,
        userPaymentInfoType: 'USERWIRETRANSFERINFO',
        bankCode: '000',
        accountNumber: '',
        note: '',
        paymentTime: ''
      },
    }
  },
  created() {
    this.initialization()
    this.getBankCode()
  },
  watch: {
    searchBankCode(val) {
      this.banksCode.data = this.recordBanksCode.filter(item => {
        const code = item.bankCode
        const name = item.name
        return code.indexOf(val) !== -1 || name.indexOf(val) !== -1
      })
    },
  },
  methods: {
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      if (!this.$route.query.id || !this.$route.query.money) return alert('請按正常方式進入本頁')
      this.paymentNotification.money = this.$route.query.money
      this.paymentNotification.id = this.$route.query.id
    },
    // 取得銀行代碼
    getBankCode () {
      const vm = this
      const getBankCodeApi = `${process.env.VUE_APP_API}/search/getBankCodeData`
      this.banksCode = {
        loading: true,
        data: []
      }
      this.recordBanksCode = []
      $.ajax({
        type: 'GET',
        async: true,
        url: getBankCodeApi,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            console.log(res.data)
            vm.banksCode = {
              loading: false,
              data: JSON.parse(JSON.stringify(res.data))
            }
            vm.recordBanksCode = JSON.parse(JSON.stringify(res.data))
          } else {
            vm.SweetAlert(res.code, res.message)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        },
      })
    },
    // 送出
    updatePaymentNotification() {
      const vm = this
      const updatePaymentNotificationApi = `${process.env.VUE_APP_API}/paymentNotification/update`
      const header = {
        authorization: this.serverToken
      }
      if (!this.paymentNotification.paymentTime) return this.SweetAlert('other', '付款時間尚未填寫!')
      const data = [
        {
          id: this.paymentNotification.id,
          userPaymentInfoType: this.paymentNotification.userPaymentInfoType,
          userPaymentInfo: {
            bankCode: this.paymentNotification.bankCode,
            accountNumber: this.paymentNotification.accountNumber
          },
          money: parseInt(this.paymentNotification.money),
          note: this.paymentNotification.note,
          paymentTime: new Date(this.paymentNotification.paymentTime) * 1
        }
      ]
      $.ajax({
        type: 'PUT',
        async: true,
        url: updatePaymentNotificationApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.$router.push('/user/PaymentRecord')
          } else {
            vm.SweetAlert(res.code, res.message)
          }
        },
        error: function(err) {
          console.log(err)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        },
      })
    },
  },
}
</script>